<template>
  <div :id="id" sytle="height: auto"></div>
</template>

<script>
  import * as echarts from 'echarts'

  export default {
    name: 'Echart01',
    props: ['id', 'items', 'datas'],
    data() {
      return {
        height: null
      }
    },
    computed: {
      mapData() {
        if(!this.items || !this.datas) return []
        const result = []
        this.items.map(item => {
          const arr = []
          this.datas.map(e => {
            arr.unshift(e[item])
          })
          result.push(arr)
        })
        return result
      }
    },
    watch: {
      datas: {
        deep: true,
        handler: function(newV, oldV) {
          if(!newV) return
          this.height = newV.length > 5 ? this.datas.length*60 : 300
          this.draw()
        }
      }
    },
    mounted() {
      // this.draw()
    },
    methods: {
      draw() {
        if (document.getElementById(this.id) == null) {
          return
        }
        echarts.dispose(document.getElementById(this.id))
        const myChart = echarts.init(document.getElementById(this.id))
        myChart.on('click', params => {
          let id = params.name || params.value
          this.$router.push({
            path: `/reportDetail/${id}`
          })
        })
        myChart.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            itemWidth: 16,
            itemHeight : 8,
            textStyle: {
              color: 'rgba(255, 255, 255, .8)'
            },
            padding: [24, 0, 0, 0]
          },
          grid: {
            left: '4%',
            right: '10%',
            bottom: 20,
            top: 50,
            containLabel: true
          },
          xAxis: {
            type: 'value',
            name: 'w',
            nameTextStyle: {
              fontSize: 10
            },
            boundaryGap: [0, 0.01],
            splitLine: {
              lineStyle: {
                color:'rgba(255, 255, 255, .1)',
                type: 'dashed'
              }
            },
            axisLabel: {
            	color: 'rgba(255, 255, 255, .6)',
            	fontSize: 10,
              interval: 0,
              rotate: 30 
            },
          },
          yAxis: {
            type: 'category',
            triggerEvent: true,
            data: this.mapData[0],
            axisLabel: {
            	color: '#2dfcff',
            	fontSize: 12
            }
          },
          series: [{
              name: '体量金额',
              type: 'bar',
              barWidth: 8,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  { offset: 0, color: '#06ff98' },
                  { offset: 1, color: '#2dfcff' }
                ])
              },
              data: this.mapData[1],
              label:{
                show: true,
                position: 'right',
                color: 'rgba(255, 255, 255, .8)',
                fontSize: 10
              }
            },
            {
              name: '机会额',
              type: 'bar',
              barWidth: 8,
              barGap: '40%',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  { offset: 0, color: '#ffda2d' },
                  { offset: 1, color: '#ff6f06' }
                ])
              },
              data: this.mapData[2],
              label:{
                show: true,
                position: 'right',
                color: 'rgba(255, 255, 255, .8)',
                fontSize: 10
              }
            }
          ]
        }, true)
        myChart.resize({height: this.height})
      }
    }
  }
</script>

<style>
</style>
