<template>
  <div :id="id" :style="`height:${height};`"></div>
</template>

<script>
  import * as echarts from 'echarts'

  export default {
    name: 'Echart02',
    props: ['id', 'height', 'datas', 'items'],
    data() {
      return {

      }
    },
    computed: {
      mapData() {
        const result = []
        this.items.map(item => {
          const arr = []
          this.datas.map(e => {
            arr.push(e[item])
          })
          result.push(arr)
        })
        return result
      }
    },
    watch: {
      datas: {
        deep: true,
        handler: function(newV, oldV) {
          if(!newV) return
          this.draw()
        }
      }
    },
    mounted() {
      // this.draw()
    },
    methods: {
      draw() {
        if (document.getElementById(this.id) == null) {
          return
        }
        echarts.dispose(document.getElementById(this.id))
        const myChart = echarts.init(document.getElementById(this.id))
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            itemWidth: 16,
            itemHeight : 8,
            textStyle: {
              color: 'rgba(255, 255, 255, .8)'
            },
            padding: [24, 0, 0, 0]
          },
          grid: {
            left: '8%',
            right: '8%',
            bottom: '3%',
            top: 50,
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: this.mapData[0],
            axisLabel: {
            	color: '#2dfcff',
            	fontSize: 12,
              interval: 0, 
              rotate: 30 
            }
          },
          yAxis: {
            type: 'value',
            name: 'w',
            nameTextStyle: {
              fontSize: 10
            },
            boundaryGap: [0, 0.01],
            splitLine: {
              lineStyle: {
                color:'rgba(255, 255, 255, .1)',
                type: 'dashed'
              }
            },
            axisLabel: {
            	color: 'rgba(255, 255, 255, .6)',
            	fontSize: 10
            },
          },
          series: [{
              name: '机会额',
              type: 'bar',
              barWidth: 10,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#ffda2d' },
                  { offset: 1, color: '#ff6f06' }
                ])
              },
              data: this.mapData[1],
              label:{
                show: true,
                position: 'top',
                color: 'rgba(255, 255, 255, .8)',
                fontSize: 10
              }
            }
          ]
        }
        myChart.setOption(option, true)
      }
    }
  }
</script>

<style>
</style>
