<template>
  <div class="cont-box" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.6)">
    <img class="title-header" src="../assets/b-title-bg.png" />
    <div class="list">
      <div class="title-cont">
        <img class="title-img" src="../assets/b-title-des.png" />
        <p class="title">各省份市场体量及机会额</p>
        <img class="title-img resever" src="../assets/b-title-des.png" />
      </div>
      <div class="icon-list">
        <div class="icon">
          <p class="icon-title">体量总额<span>(万元)</span></p>
          <p class="icon-num">{{massSum?massSum:'--'}}</p>
        </div>
        <div class="icon">
          <p class="icon-title">机会总额<span>(万元)</span></p>
          <p class="icon-num">{{numSum?numSum:'--'}}</p>
        </div>
      </div>
      <Echart01 id="Echart01" :datas="data01" :items="['province', 'mass', 'num']"/>
    </div>
    <div class="list">
      <div class="title-cont">
        <img class="title-img" src="../assets/b-title-des.png" />
        <p class="title">各产品市场机会额</p>
        <img class="title-img resever" src="../assets/b-title-des.png" />
      </div>
      <Echart02 id="Echart03" :datas="data03" :items="['name', 'num']" height="340px" />
    </div>
    <div class="list">
      <div class="title-cont">
        <img class="title-img" src="../assets/b-title-des.png" />
        <p class="title">市场商机转化率</p>
        <img class="title-img resever" src="../assets/b-title-des.png" />
      </div>
    </div>
    <div class="list">
      <div class="title-cont">
        <img class="title-img" src="../assets/b-title-des.png" />
        <p class="title">项目商机进度分析</p>
        <img class="title-img resever" src="../assets/b-title-des.png" />
      </div>
    </div>
  </div>
</template>

<script>
  import Echart01 from '../components/Echart01.vue'
  import Echart02 from '../components/Echart02.vue'

  export default {
    name: 'ReportMarket',
    components: {
      Echart01,
      Echart02
    },
    data() {
      return {
        loading: true,
        data01: [],
        data02: [],
        data03: [],
        massSum: 0,
        numSum: 0
      }
    },
    mounted() {
      const code = this.$router.history.current.query.code
      this.$axios.post('/wechat/reportMarket',{code}).then(res => {
        if(!Object.keys(res.data).length) {
          this.data01 = this.$store.state.data01
          this.data03 = this.$store.state.data03
        } else {
          this.data01 = res.data.province
          this.data03 = res.data.product
          this.$store.commit('SET_DATA01', this.data01)
          this.$store.commit('SET_DATA03', this.data03)
        }
        this.loading = false
        this.data01.map(item => {
          this.massSum += item.mass
          this.numSum += item.num
        })
      }).catch(err => {
        this.loading = false
      })
    }
  }
</script>

<style scoped>
  .cont-box {
    background-color: #08103c;
    min-height: 100%;
  }

  .title-header {
    width: 100%;
  }

  .resever {
    transform: scaleX(-1);
  }

  .title-cont {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-img {
    width: 80px;
  }

  .title {
    color: #2dfcff;
    font-size: 16px;
    font-weight: bold;
    padding: 0 10px;
    white-space: nowrap;
  }

  .list {
    padding-bottom: 40px;
  }
  
  .icon-list {
    padding: 30px 0 10px;
    display: flex;
    justify-content: center;
  }
  .icon {
    width: 40%;
    background: rgba(0, 0, 0, .2);
    margin: 0 10px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(45, 252, 255, .2);
  }
  .icon-title {
    font-size: 14px;
    color: rgba(255, 255, 255, .8);
  }
  .icon-title>span{
    font-size: 12px;
  }
  .icon-num {
    font-size: 20px;
    font-weight: bold;
    padding-top: 6px;
    color: #2dfcff;
  }
  
  .icon:nth-of-type(2) .icon-num {
    color: #ff6f06;
  }
  
</style>
